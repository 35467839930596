@use "@scss/global.scss" as *;

.socialicon-withDropdown {
  &-target {
    cursor: pointer;
  }

  &-dropdown {
    display: flex;
    flex-flow: column nowrap;
    row-gap: 16px;
    padding: 23px 32px;
    border-radius: 10px;
    background: $gradient-3;
  }
}
