@use "./src/scss/global.scss" as *;

.staking-instances {
  hr {
    opacity: 0.2;
    height: 1px;
    width: 100%;
    background-color: $black-1;
  }
}
