@use "@scss/global.scss" as *;

.stack {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  position: relative;

  &.full-size {
    width: 100%;
    height: 100%;
  }

  &.full-width {
    width: 100%;
  }
}
