@use "./src/scss/global.scss" as *;

@function get-dash-value($radius, $percentage) {
  // Using $radius, calculate circumference.
  $circumference: calc(2 * 3.1415927 * $radius);
  // Convert percentage to decimal.   // i.e. 50% = 0.5.
  $percentage-as-decimal: calc($percentage / 100%);
  // Return unit value.
  @return calc($circumference * $percentage-as-decimal);
}

.modal-status-loading {
  $circle-radius: 37;

  &-svg {
    animation: circle-rotate 2s linear infinite both;
  }

  &-svg-circle {
    fill: transparent;
    stroke-dasharray: get-dash-value($circle-radius, 100%);
    // stroke-linecap: round;
    stroke-width: 5;
    transform-origin: 50% 50%;
    animation: svg-circle-anim 1.4s ease-in-out infinite both;
    stroke: url(#modal-loader-gradient);
  }

  @keyframes circle-rotate {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }

  @keyframes svg-circle-anim {
    0% {
      stroke-dashoffset: get-dash-value($circle-radius, 97%);
      transform: rotate(0);
    }

    50% {
      stroke-dashoffset: get-dash-value($circle-radius, 25%);
      transform: rotate(45deg);
    }

    100% {
      stroke-dashoffset: get-dash-value($circle-radius, 97%);
      transform: rotate(360deg);
    }
  }
}
