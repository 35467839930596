@use "@scss/global.scss" as *;

.app-container {
  margin: 0 auto;
  padding: 0 72px;

  &-xxl {
    max-width: $page-max-width;
  }

  &-xl {
    max-width: $xl;
  }

  &.full-size {
    min-width: 100%;
    min-height: 100%;
  }
}
