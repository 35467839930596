@use "@scss/global.scss" as *;

.staking-info-card {
  position: relative;
  background: $white-0;
  border: 1px solid $white-7;
  border-radius: 20px;
  // width: 100%;
  height: 154px;
  padding: 14px;

  &-wrapper {
    display: flex;
    flex-flow: column nowrap;
    row-gap: 12px;
    max-width: 272px;
    width: 100%;
  }

  &::before {
    content: "";
    position: absolute;
    border-radius: 20px;
    inset: 0;
    left: -1px;
    top: -1px;
    right: -1px;
    bottom: -1px;
    padding: 2.75px;
    background: $gradient-2;
    mask: linear-gradient($white-0 0 0) content-box, $gradient-2 padding-box;
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  &:hover,
  &--active {
    &::before {
      opacity: 1;
    }
  }

  &--active {
    background: $white-1;
  }

  &.card-disabled {
    opacity: 0.6;
    pointer-events: none;

    &::before {
      opacity: 0;
    }
  }

  &.full-width {
    width: 100%;
  }

  &-info-icon {
    position: absolute;
    top: 14px;
    right: 14px;
    z-index: 10;
    cursor: help;
  }
}
