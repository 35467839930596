@use "@scss/global.scss" as *;

.skey-network-link {
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  letter-spacing: 0.03em;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: $primary;
  }
}
