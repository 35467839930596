@use "@scss/global.scss" as *;

.wallet-address {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 12px;
  width: max-content;
  height: max-content;
  padding: 5px 14.5px;
  background: $white-7;
  border-radius: 33px;
  cursor: pointer;
}
