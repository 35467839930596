@use "@scss/global.scss" as *;

.staking-amount {
  display: flex;
  flex-flow: column nowrap;
  row-gap: 12px;
  // max-width: 575px;
  width: 100%;

  &-container {
    border: 1px solid $white-7;
    border-radius: 20px;
    width: 100%;
    padding: 22px 16px;

    display: flex;
    flex-flow: column nowrap;
    row-gap: 16px;

    .input-buttons {
      display: flex;
      flex-flow: row nowrap;
      gap: 8px;
    }
  }
}
