@use "@scss/global.scss" as *;

.days-progress-bar {
  --progress-width: 0%;

  display: flex;
  flex-flow: column nowrap;
  width: 100%;

  &-info {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .progress-bar {
    position: relative;
    z-index: 0;

    .line-inactive {
      width: 100%;
      height: 6px;
      background: $white-7;
      border-radius: 100px;
      position: absolute;
      z-index: 0;
    }

    .line-active {
      height: 6px;
      width: var(--progress-width);
      background: $gradient-2;
      border-radius: 100px;
      position: absolute;
      max-width: 100%;
      z-index: 2;
      animation: load-progress 2s normal forwards;
    }
  }

  &-completed {
    .line-active {
      background: #3aa12f !important;
    }
  }

  .progress-bar-completed-icon {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 4px;
  }

  @keyframes load-progress {
    0% {
      width: 0;
    }
    100% {
      width: var(--progress-width);
    }
  }
}
