@use "@scss/global.scss" as *;

.percentage-button {
  position: relative;
  background: $white-0;
  border: 2px solid $white-2;
  border-radius: 8px;
  padding: 3.5px 24px;
  cursor: pointer;
  font-weight: $regular;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.04em;
  transition: background-color 0.2s ease-in-out;

  &::before {
    content: "";
    position: absolute;
    border-radius: 8px;
    inset: 0;
    left: -1px;
    top: -1px;
    right: -1px;
    bottom: -1px;
    padding: 2px;
    background: $gradient-2;
    mask: linear-gradient($white-0 0 0) content-box, $gradient-2 padding-box;
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  &:hover {
    background: $white-8;
  }

  &:active,
  &--active {
    &::before {
      opacity: 1;
    }
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.6;
  }

  &.full-width {
    width: 100%;
  }
}
