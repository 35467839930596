@use "@scss/global.scss" as *;

.app-layout {
  position: relative;
  height: 100%;

  header.app-navbar {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: $navbar-height;
    z-index: 10;
    background: $white-0;
    box-shadow: $box-shadow-0;
  }

  main.app-content {
    padding-top: $navbar-height;
    height: max-content;
    min-height: 100%;
    width: 100%;
    display: flex;

    > * {
      flex: 1;
    }
  }

  footer.app-footer {
    height: max-content;
    width: 100%;

    .app-footer-content {
      background: $black-1;
    }

    .app-footer-copyright {
      background: $black-0;
    }
  }
}
