@use "./src/scss/global.scss" as *;

.staking-stage {
  &-wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    column-gap: 30px;
    width: 100%;
  }

  &-info {
    display: flex;
    flex-flow: column nowrap;
    max-width: 410px;
    width: 100%;
    row-gap: 48px;
  }

  &-content {
    max-width: 575px;
    width: 100%;
  }

  &-info-cards {
    display: flex;
    flex-flow: row wrap;
    gap: 32px 10px;
    justify-content: space-between;
    width: 100%;
  }

  @include mq-max($small-laptop) {
    &-wrapper {
      flex-flow: column nowrap;
      row-gap: 48px;
      align-items: center;
    }

    &-info {
      row-gap: 24px;
      max-width: unset;
      width: 100%;
    }

    &-content {
      max-width: unset;
      width: 100%;
    }

    &-info-cards {
      justify-content: flex-start;
      column-gap: 32px;
    }
  }
}
