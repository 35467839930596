@use "@scss/global.scss" as *;

.staking-hero {
  padding: 150px 0 200px;
  position: relative;
  overflow: hidden;
  min-height: 700px;

  &-background {
    position: absolute;
    top: -310px;
    left: 50%;
    transform: translateX(-50%);
  }

  &-button {
    min-width: 368px;
  }

  .description {
    max-width: 700px;
    text-align: center;
  }
}
