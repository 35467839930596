@use "@scss/variables.scss" as *;
@use "@scss/mixins.scss" as *;

.desktop-xs {
  display: block;

  @include mq-min($xs) {
    display: none;
  }
}

.mobile-xs {
  display: none;

  @include mq-min($xs) {
    display: block;
  }
}

.desktop-sm {
  display: block;

  @include mq-min($sm) {
    display: none;
  }
}

.mobile-sm {
  display: none;

  @include mq-min(sm) {
    display: block;
  }
}

.desktop-md {
  display: block;

  @include mq-min($md) {
    display: none;
  }
}

.mobile-md {
  display: none;

  @include mq-min($md) {
    display: block;
  }
}

.desktop-lg {
  display: block;

  @include mq-min($lg) {
    display: none;
  }
}

.mobile-lg {
  display: none;

  @include mq-min($lg) {
    display: block;
  }
}

.desktop-xl {
  display: block;

  @include mq-min($xl) {
    display: none;
  }
}
.mobile-xl {
  display: none;

  @include mq-min($xl) {
    display: block;
  }
}

.desktop-xxl {
  display: block;

  @include mq-min($xxl) {
    display: none;
  }
}

.mobile-xxl {
  display: none;

  @include mq-min($xxl) {
    display: block;
  }
}
