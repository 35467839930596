@use "@scss/global.scss" as *;

.link {
  display: inline-block;
  cursor: pointer;
  width: max-content;
  font-weight: $regular;
  line-height: $lh-6;
  letter-spacing: $ls-4;
  transition: color, stroke 0.2s ease-in-out;

  &-primary {
    color: $primary;

    &:hover {
      color: $black-1;

      svg path {
        stroke: $black-1;
      }
    }
  }

  &-secondary {
    color: $primary-dark;

    &:hover {
      color: $primary-dark-hover;

      svg path {
        stroke: $primary-dark-hover;
      }
    }
  }

  &-normal {
    font-size: 15px;
  }
  &-small {
    font-size: 12px;
  }

  &.disabled {
    pointer-events: none;
    cursor: default;
  }
}
