// COLORS
$primary: #5c44ec;
$primary-dark: #7f70db;
$primary-dark-hover: #998de2;

$white-0: #ffffff;
$white-1: #fafafc;
$white-2: #eeeef1;
$white-3: #f3f3f4;
$white-4: #b8b7c1;
$white-5: #d0cfd5;
$white-6: #a09fac;
$white-7: #e8e7eb;
$white-8: #f5f5f5;

$black-0: #000000;
$black-1: #120e2f;
$black-2: #0b091c;

$success: #3aa12f;
$danger: #e63a1a;
$warning: #ffaf39;
// COLORS

// BOX-SHADOWS
$box-shadow-0: 0px 12px 17px rgba(0, 0, 0, 0.02), 0px 4.62222px 5.41481px rgba(0, 0, 0, 0.0121481),
  0px 0.977778px 1.38519px rgba(0, 0, 0, 0.00785185);

$box-shadow-1: 9.77335px 19.7099px 19px rgba(0, 0, 0, 0.07), 4.94776px 9.97816px 8.28281px rgba(0, 0, 0, 0.04725),
  1.95467px 3.94199px 3.0875px rgba(0, 0, 0, 0.035), 0.427584px 0.86231px 1.09844px rgba(0, 0, 0, 0.02275);

$box-shadow-2: 0px 8px 15px rgba(0, 0, 0, 0.04), 0px 0px 2.92553px rgba(0, 0, 0, 0.04);

$box-shadow-3: 8px 8px 40px rgba(18, 14, 47, 0.02), 0px 46.8086px 70.2128px rgba(0, 0, 0, 0.04),
  -4px 11.7021px 23.4043px rgba(0, 0, 0, 0.02), 0px 0px 2.92553px rgba(0, 0, 0, 0.04);

$box-shadow-4: 0px 46.8086px 70.2128px rgba(0, 0, 0, 0.06), 0px 11.7021px 23.4043px rgba(0, 0, 0, 0.04),
  0px 0px 2.92553px rgba(0, 0, 0, 0.04);
// BOX-SHADOWS

// GRADIENTS
$gradient-1: linear-gradient(270.16deg, #3495ff 0.02%, #5c44ec 43.38%, #bb5dee 97.16%);
$gradient-2: linear-gradient(270.16deg, #3495ff 0.02%, #5c44ec 97.16%);
$gradient-3: linear-gradient(159.1deg, rgba(255, 255, 255, 0.1) -0.09%, rgba(255, 255, 255, 0) 171.38%), #120e2f;
// GRADIENTS

// BREAKPOINTS
$xs: 320px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1140px;
$xxl: 1440px;

$breakpoints: (
  xs: $xs,
  sm: $sm,
  md: $md,
  lg: $lg,
  xl: $xl,
  xxl: $xxl
);
// BREAKPOINTS

// dX
$size-dx: 8px;
// dX

$navbar-height: 72px;
$page-max-width: 1440px;
$small-laptop: 1330px;

// SIZES
$size-1: $size-dx;
$size-2: $size-dx * 2;
$size-3: $size-dx * 3;
$size-4: $size-dx * 4;
$size-5: $size-dx * 5;
$size-6: $size-dx * 6;
$size-7: $size-dx * 7;
$size-8: $size-dx * 8;
$size-9: $size-dx * 9;
$size-10: $size-dx * 10;

$size-variants: (
  1: $size-1,
  2: $size-2,
  3: $size-3,
  4: $size-4,
  5: $size-5,
  6: $size-6,
  7: $size-7,
  8: $size-8,
  9: $size-9,
  10: $size-10
);
// SIZES

$display-variants: (
  b: block,
  f: flex,
  n: none
);

// FONT WEIGHTS
$light: 300;
$regular: 400;
$medium: 500;
$bold: 700;
// FONT WEIGHTS

// LINE HEIGHTS
$lh-1: 120%;
$lh-2: 130%;
$lh-3: 140%;
$lh-4: 150%;
$lh-5: 160%;
$lh-6: 170%;
// LINE HEIGHTS

// LETTER SPACING
$ls-1: -0.02em;
$ls-2: -0.01em;
$ls-3: 0.01em;
$ls-4: 0.02em;
// LETTER SPACING

// FONT-SIZES
$fs-0: 48px;
$fs-1: 38px;
$fs-2: 28px;
$fs-3: 20px;
$fs-4: 18px;
$fs-5: 15px;
$fs-6: 12px;
// FONT-SIZES
