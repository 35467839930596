@use "@scss/global.scss" as *;

.unsupported-browser {
  padding-top: 16px;
  width: 550px;

  img {
    width: 50px;
    height: 50px;
  }
}
