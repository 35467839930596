@use "./src/scss/global.scss" as *;

.prelaunch-stage {
  &-wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    column-gap: 30px;
    width: 100%;
  }

  &-info {
    display: flex;
    flex-flow: column nowrap;
    max-width: 410px;
    width: 100%;
    row-gap: 48px;
  }

  &-content {
    max-width: 575px;
    width: 100%;
  }

  @include mq-max($small-laptop) {
    &-wrapper {
      flex-flow: column nowrap;
      row-gap: 48px;
      align-items: center;
    }

    &-info {
      row-gap: 24px;
      max-width: unset;
      width: 100%;
    }

    &-content {
      max-width: unset;
      width: 100%;
    }
  }

  .horizontal-rule {
    height: 1px;
    background-color: $black-0;
    opacity: 0.1;
    width: 100%;
  }

  .form-error {
    color: $danger;
    width: 100%;
    text-align: center;
  }

  .withdraw-description {
    width: 100%;
    text-align: center;
  }
}
