@use "@scss/global.scss" as *;

.amount-input {
  position: relative;
  color: $black-1;
  border-radius: 8px;

  &-input {
    position: relative;
    padding: 7.5px;
    padding-right: 96px;
    margin: 0;
    outline: none;
    border: none;
    border-radius: 8px;
    min-height: 60px;
    width: 100%;
    background: $white-8;
    caret-color: $black-1;
    z-index: 1;

    text-align: right;
    font-weight: $bold;
    font-size: 32px;
    line-height: 140%;
    letter-spacing: -0.02em;

    &:focus + .amount-input-border::before {
      opacity: 1;
    }

    &::placeholder {
      color: rgba($black-1, 0.5);
    }

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      opacity: 0;
      display: none;
    }

    &:disabled {
      opacity: 0.6;
    }

    // &::-webkit-outer-spin-button,
    // &::-webkit-inner-spin-button {
    //   -webkit-appearance: inner-spin-button !important;
    //   width: 25px;
    //   position: absolute;
    //   top: 0;
    //   right: 5px;
    //   height: 100%;
    // }
  }

  &-currency {
    position: absolute;
    top: 7.5px;
    right: 32px;
    font-weight: $regular;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: 0.01em;
    pointer-events: none;
    z-index: 1;
  }

  &-border::before {
    content: "";
    position: absolute;
    border-radius: 8px;
    inset: 0;
    left: -1px;
    top: -1px;
    right: -1px;
    bottom: -1px;
    margin: -1.75px;
    background: $gradient-2;
    opacity: 0;
    z-index: 0;
    pointer-events: none;
    transition: opacity 0.2s ease-in-out;
  }
}
