@use "./src/scss/global.scss" as *;

.debug {
  max-width: 700px;
  width: 100%;
  margin: auto;
  transform: rotate(-0.5deg);

  &-title {
    margin: auto;
  }

  .collapse-btn {
    display: flex;
    flex-direction: row;
    color: $black-0;
    cursor: pointer;

    &:before,
    &:after {
      content: "";
      flex: 1 1;
      border-bottom: 2px solid $white-5;
      margin: auto 10px;
    }
  }
}
