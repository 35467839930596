@use "./src/scss/global.scss" as *;

.button {
  min-width: 200px;
  width: max-content;
  height: 59px;
  font-weight: $regular;
  line-height: $lh-4;
  font-size: 18px;
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  &-content {
    display: flex;
    flex-flow: row nowrap;
    gap: 8px;
    justify-content: center;
    align-items: center;
  }

  &.primary {
    padding: 16px 32px;
    border-radius: 8px;
    letter-spacing: 0.01em;
    background: $gradient-2;
    color: $white-0;

    &:hover {
      box-shadow: $box-shadow-1;
    }
  }

  &.secondary {
    padding: 16px 24px;
    border-radius: 10px;
    letter-spacing: 0.04em;
    background: $white-0;
    color: $primary;
    border: 1px solid $white-2;

    &:hover {
      color: $black-1;
      box-shadow: $box-shadow-3;
    }
  }

  &.text {
    color: $primary;
    min-width: min-content;
    height: max-content;
    padding: 16px 10px;

    &:hover {
      color: $black-1;
    }
  }

  &:disabled,
  &-loading {
    pointer-events: none;
    opacity: 0.6;
  }

  &.full-size {
    width: 100%;
  }

  &.free-width {
    min-width: unset;
    width: 100%;
  }
}
