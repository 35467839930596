@use "@scss/global.scss" as *;

.countdown-timer {
  display: flex;
  flex-flow: row nowrap;
  column-gap: 48px;
  align-items: flex-start;

  color: $primary;
  background: $primary;
  background-image: $gradient-2;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;

  &-time-section {
    display: flex;
    column-gap: 8px;
  }

  .with-values {
    min-width: 120px;
  }

  &-column {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    .timer-value {
      font-family: "Mazzard";
      font-weight: $bold;
      font-size: 80px;
      line-height: 95px;
    }

    .timer-value-name {
      font-weight: $regular;
      font-size: 15px;
      line-height: 19px;
      letter-spacing: 0.01em;

      color: $white-6;
      background: $white-6;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-background-clip: text;
      -moz-text-fill-color: transparent;
    }
  }
}
