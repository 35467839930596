@use "./variables.scss" as *;

@import url("https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap");

@font-face {
  font-family: Mazzard;
  src: url("../../public/fonts/MazzardH-Black.otf") format("opentype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: Mazzard;
  src: url("../../public/fonts/MazzardH-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

hr,
a,
button,
div,
span,
label,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  padding: 0px;
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
}

h1 {
  font-weight: $regular;
  font-size: 38px;
  line-height: 140%;
  letter-spacing: -0.01em;
}

h2 {
  font-weight: $regular;
  font-size: 28px;
  line-height: 140%;
  letter-spacing: -0.02em;
}

h3 {
  font-weight: $regular;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: 0.01em;
}

h4 {
  font-weight: $regular;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 0.01em;
}

p,
.p0 {
  font-weight: $regular;
  font-size: 15px;
  line-height: 170%;
  letter-spacing: 0.01em;
}

.p1 {
  font-weight: $regular;
  font-size: 12px;
  line-height: 170%;
  letter-spacing: 0.02em;
}

.p2 {
  font-weight: $regular;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: 0.01em;
}

// VARIABLE-BASED TOOL
.light {
  font-weight: $light;
}
.regular {
  font-weight: $regular;
}
.medium {
  font-weight: $medium;
}
.bold {
  font-weight: $bold;
}

.lh-1 {
  line-height: $lh-1;
}
.lh-2 {
  line-height: $lh-2;
}
.lh-3 {
  line-height: $lh-3;
}
.lh-4 {
  line-height: $lh-4;
}
.lh-5 {
  line-height: $lh-5;
}
.lh-6 {
  line-height: $lh-6;
}

.ls-1 {
  letter-spacing: $ls-1;
}
.ls-2 {
  letter-spacing: $ls-2;
}
.ls-3 {
  letter-spacing: $ls-3;
}
.ls-4 {
  letter-spacing: $ls-4;
}

.fs-0 {
  font-size: $fs-0;
}
.fs-1 {
  font-size: $fs-1;
}
.fs-2 {
  font-size: $fs-2;
}
.fs-3 {
  font-size: $fs-3;
}
.fs-4 {
  font-size: $fs-4;
}
.fs-5 {
  font-size: $fs-5;
}
.fs-6 {
  font-size: $fs-6;
}

.primary {
  color: $primary;
}
.primary-bg {
  background-color: $primary;
}

.primary-dark {
  color: $primary-dark;
}
.primary-dark-bg {
  background-color: $primary-dark;
}

.primary-dark-hover {
  color: $primary-dark-hover;
}
.primary-dark-hover-bg {
  background-color: $primary-dark-hover;
}

.white-0 {
  color: $white-0;
}
.white-0-bg {
  background-color: $white-0;
}

.white-1 {
  color: $white-1;
}
.white-1-bg {
  background-color: $white-1;
}

.white-2 {
  color: $white-2;
}
.white-2-bg {
  background-color: $white-2;
}

.white-3 {
  color: $white-3;
}
.white-3-bg {
  background-color: $white-3;
}

.white-4 {
  color: $white-4;
}
.white-4-bg {
  background-color: $white-4;
}

.white-5 {
  color: $white-5;
}
.white-5-bg {
  background-color: $white-5;
}

.white-6 {
  color: $white-6;
}
.white-6-bg {
  background-color: $white-6;
}

.white-7 {
  color: $white-7;
}
.white-7-bg {
  background-color: $white-7;
}

.white-8 {
  color: $white-7;
}
.white-8-bg {
  background-color: $white-7;
}

.black-0 {
  color: $black-0;
}
.black-0-bg {
  background-color: $black-0;
}

.black-1 {
  color: $black-1;
}
.black-1-bg {
  background-color: $black-1;
}

.black-2 {
  color: $black-2;
}
.black-2-bg {
  background-color: $black-2;
}

.success {
  color: $success;
}
.success-bg {
  background-color: $success;
}

.danger {
  color: $danger;
}
.danger-bg {
  background-color: $danger;
}

.warning {
  color: $warning;
}
.warning-bg {
  background-color: $warning;
}

.gradient-text {
  color: $primary;
  background: $primary;
  background-image: $gradient-2;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}
// VARIABLE-BASED TOOL
