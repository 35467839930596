@use "@scss/global.scss" as *;

.stage-card {
  padding: 72px 86px 137px;
  width: 100%;
  border-radius: 20px;
  position: relative;
  margin-bottom: 53px;
  transition-property: opacity, background-color;
  transition: 0.2s ease-in-out;

  .hidden-triangle {
    display: none;
  }

  .stage-card-triangle:after,
  .stage-card-triangle:before {
    position: absolute;
    content: "";
    top: 100%;
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
  }

  .stage-card-triangle:after {
    border-width: 25px 25px;
    margin-left: -25px;
  }

  .stage-card-triangle:before {
    border-width: 27px 27px;
    margin-left: -27px;
  }

  &-active {
    background: $white-0;
    box-shadow: $box-shadow-4;
    opacity: 1;

    .stage-card-triangle:after {
      border-top-color: $white-0;
    }
  }

  &-inactive {
    background: $white-8;
    border: 2px solid rgba(0, 0, 0, 0.1);
    opacity: 0.7;

    .stage-card-triangle:after {
      border-top-color: $white-8;
    }

    .stage-card-triangle:before {
      border-top-color: rgba(0, 0, 0, 0.1);
    }
  }
}
