@use "@scss/global.scss" as *;

.group {
  display: flex;
  flex-direction: row;
  width: max-content;
  height: max-content;

  &.full-width {
    width: 100%;
  }

  &.full-size {
    height: 100%;
    width: 100%;
  }
}
