@use "@scss/global.scss" as *;

.modal {
  height: max-content;
  width: max-content;
  max-width: 100%;
  height: auto;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 40px;
  border-radius: 20px;
  background: $white-0;
  box-shadow: $box-shadow-4;

  &-close-btn {
    position: absolute;
    top: 0;
    right: 0;
  }

  @include mq-max($sm) {
    max-width: 90%;
  }
}
