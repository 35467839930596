@use "./src/scss/global.scss" as *;

.collapse-deposit-more {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
  cursor: pointer;
  color: $primary;
  transition: color 0.2s ease-in-out;

  &-icon {
    transition: transform 0.4s ease-in-out;

    path {
      transition: stroke 0.2s ease-in-out;
    }
  }

  &:hover {
    color: $primary-dark;

    svg path {
      stroke: $primary-dark;
    }
  }

  &:before,
  &:after {
    content: "";
    flex: 1 1;
    height: 1px;
    background-color: $black-0;
    opacity: 0.1;
    margin: auto;
  }

  &:before {
    margin-right: 10px;
  }
  &:after {
    margin-left: 10px;
  }

  &-active {
    .collapse-deposit-more-icon {
      transform: rotate(180deg);
    }
  }
}
