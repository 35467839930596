@use "@scss/global.scss" as *;

.staking-faq {
  padding: 120px 0 130px;

  .faq-answer-content {
    max-width: 700px;
    white-space: break-spaces;
  }

  .add-token-btn {
    font-size: 15px;
  }
}
