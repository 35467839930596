@use "@scss/global.scss" as *;

.footer {
  padding: 40px 0px;

  .footer-row {
    &-socials {
      width: 33.33%;
      flex: 0 0 auto;
    }

    &-legal-disclaimer {
      width: 25%;
    }

    &-contact {
      width: 16.67%;
    }

    &-project-reporting {
      width: 25%;
    }
  }
}
